<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M6.66667 10H13.3333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 13.3337V6.66699" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.50001 18.3337H12.5C16.6667 18.3337 18.3333 16.667 18.3333 12.5003V7.50033C18.3333 3.33366 16.6667 1.66699 12.5 1.66699H7.50001C3.33334 1.66699 1.66667 3.33366 1.66667 7.50033V12.5003C1.66667 16.667 3.33334 18.3337 7.50001 18.3337Z" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>